var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ManagementLayout',{attrs:{"show":""},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v("API Keys")]},proxy:true},{key:"subtitle",fn:function(){return [_vm._v(" API keys are used to represent a single user, and are typically used by Prefect Cloud clients (such as the Prefect Cloud CLI) to perform actions within Prefect. ")]},proxy:true},{key:"cta",fn:function(){return [_c('v-btn',{staticClass:"white--text",attrs:{"color":"blue","data-cy":"create-api-key"},on:{"click":function($event){_vm.createKeyDialog = true}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" add ")]),_vm._v(" Create an API key ")],1)]},proxy:true}])},[(!_vm.$vuetify.breakpoint.mdAndUp)?_c('v-text-field',{attrs:{"solo":"","dense":"","hide-details":"","single-line":"","placeholder":"Search for an API Key","prepend-inner-icon":"search","autocomplete":"new-password"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}):_vm._e(),_c('v-card',{attrs:{"tile":""}},[_c('v-card-text',{staticClass:"pa-0"},[(_vm.$vuetify.breakpoint.mdAndUp)?_c('div',{staticClass:"py-1 mr-2 flex"},[_c('v-text-field',{staticClass:"rounded-0 elevation-1",style:({
            'max-width': _vm.$vuetify.breakpoint.mdAndUp ? '360px' : null
          }),attrs:{"solo":"","dense":"","hide-details":"","single-line":"","placeholder":"Search for an API Key","prepend-inner-icon":"search","autocomplete":"new-password"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1):_vm._e(),_c('v-data-table',{staticClass:"elevation-2 rounded-0 truncate-table",attrs:{"fixed-header":"","headers":_vm.headers,"header-props":{ 'sort-icon': 'arrow_drop_up' },"search":_vm.search,"items":_vm.keys,"loading":_vm.$apollo.queries.keys.loading,"items-per-page":10,"footer-props":{
          showFirstLastPage: true,
          firstIcon: 'first_page',
          lastIcon: 'last_page',
          prevIcon: 'keyboard_arrow_left',
          nextIcon: 'keyboard_arrow_right'
        },"no-results-text":"No API keys found. Try expanding your search?","no-data-text":"You do not have any API keys yet."},scopedSlots:_vm._u([{key:"header.name",fn:function(ref){
        var header = ref.header;
return [_c('span',{staticClass:"text-subtitle-2"},[_vm._v(_vm._s(header.text))])]}},{key:"header.created",fn:function(ref){
        var header = ref.header;
return [_c('span',{staticClass:"text-subtitle-2"},[_vm._v(_vm._s(header.text))])]}},{key:"header.expires_at",fn:function(ref){
        var header = ref.header;
return [_c('span',{staticClass:"text-subtitle-2"},[_vm._v(_vm._s(header.text))])]}},{key:"header.tenant",fn:function(ref){
        var header = ref.header;
return [_c('span',{staticClass:"text-subtitle-2"},[_vm._v(_vm._s(header.text))])]}},{key:"item.name",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)+" ")]}},{key:"item.created",fn:function(ref){
        var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v(" "+_vm._s(item.created_at ? _vm.formDate(item.created_at) : '')+" ")])]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(item.created_at ? _vm.formatTime(item.created_at) : '')+" ")])])]}},{key:"item.expires_at",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(item.expires ? _vm.formatTimeRelative(item.expires) : 'Never')+" ")]}},{key:"item.actions",fn:function(ref){
        var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"text":"","fab":"","x-small":"","color":"error"},on:{"click":function($event){_vm.keyToDelete = item
                  _vm.keyToDeleteDialog = true}}},on),[_c('v-icon',[_vm._v("delete")])],1)]}}],null,true)},[_vm._v(" Revoke API key ")])]}}])})],1)],1),_c('ConfirmDialog',{attrs:{"dialog-props":{ 'max-width': '500' },"confirm-props":{ 'data-cy': 'submit-new-api-key' },"disabled":!_vm.newKeyFormFilled,"title":"Create an API key","confirm-text":"Create","loading":_vm.creatingKey},on:{"cancel":function($event){_vm.createKeyDialog = false
      _vm.resetNewKey()},"confirm":function($event){return _vm.createAPIKey({
        user_id: _vm.user.id,
        name: _vm.newKeyName,
        expires_at: _vm.expiresAt,
        tenant_id: _vm.newKeyTenant.id
      })}},model:{value:(_vm.createKeyDialog),callback:function ($$v) {_vm.createKeyDialog=$$v},expression:"createKeyDialog"}},[_c('v-text-field',{staticClass:"mb-3",attrs:{"single-line":"","data-cy":"new-api-key-name","placeholder":"API Key Name","autofocus":"","outlined":"","dense":""},model:{value:(_vm.newKeyName),callback:function ($$v) {_vm.newKeyName=$$v},expression:"newKeyName"}}),_c('DateTime',{staticClass:"mb-3",attrs:{"warning":"\n        You have selected a time in the past; as a result, your API key will have already expired.\n      ","text-field-props":{
        outlined: true,
        dense: true,
        hint: "Leave blank to never expire this key",
        label: 'API Key Expiration',
        persistentHint: true
      }},model:{value:(_vm.expiresAt),callback:function ($$v) {_vm.expiresAt=$$v},expression:"expiresAt"}}),_c('v-select',{attrs:{"outlined":"","dense":"","return-object":"","label":"Tenant","items":_vm.tenants,"item-text":"name","item-value":"id"},model:{value:(_vm.newKeyTenant),callback:function ($$v) {_vm.newKeyTenant=$$v},expression:"newKeyTenant"}})],1),_c('ConfirmDialog',{attrs:{"dialog-props":{ 'max-width': '500' },"cancel-props":{ 'data-cy': 'close-api-key-dialog' },"title":"Your key has been created","confirm-text":_vm.keyCopied ? 'Copied' : 'Copy',"cancel-text":"Close"},on:{"cancel":_vm.resetNewKey,"confirm":_vm.copyNewKey},model:{value:(_vm.copyKeyDialog),callback:function ($$v) {_vm.copyKeyDialog=$$v},expression:"copyKeyDialog"}},[_c('p',[_vm._v(" Copy this key and put it in a secure place. "),_c('strong',[_vm._v(" You won't be able to see this key again once you close this dialog. ")])]),_c('v-textarea',{staticClass:"_lr-hide",attrs:{"id":"new-api-key","data-cy":"api-key-field","data-private":"","auto-grow":"","rows":"1","readonly":"","single-line":"","outlined":"","spellcheck":"false"},model:{value:(_vm.newKey),callback:function ($$v) {_vm.newKey=$$v},expression:"newKey"}})],1),(_vm.keyToDelete)?_c('ConfirmDialog',{attrs:{"type":"error","dialog-props":{ 'max-width': '500' },"title":("Are you sure you want to revoke the API key\n        " + (_vm.keyToDelete.name) + "?"),"confirm-text":"Revoke"},on:{"confirm":function($event){return _vm.deleteKey(_vm.keyToDelete)}},model:{value:(_vm.keyToDeleteDialog),callback:function ($$v) {_vm.keyToDeleteDialog=$$v},expression:"keyToDeleteDialog"}},[_vm._v(" Once you delete this key, you will not be able to use it again to interact with the Prefect Cloud API. ")]):_vm._e(),_c('Alert',{attrs:{"type":_vm.alertType,"message":_vm.alertMessage,"offset-x":_vm.$vuetify.breakpoint.mdAndUp ? 256 : 56},model:{value:(_vm.alertShow),callback:function ($$v) {_vm.alertShow=$$v},expression:"alertShow"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }